<template>
  <div>
    <v-dialog width="400" v-model="dialog">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-8">
          <div id="print">
            <div style="display: flex; align-items: center; padding: 5px;border-bottom: 1px solid #e1e1e1">
              <img :src="require('@/assets/logo2.png')" alt="logo" style="max-width: 30mm; height: auto;">
              <div style="padding-left: 5px;">
                <h3 style="margin: 0; font-size: 14px;">Bon Gifty</h3>
                <p style="font-size: 10px; margin: 0; color: #666;">Merci pour votre confiance</p>
              </div>
            </div>

            <div style="margin-top: 10px;">
              <table style="width: 100%;">
                <tr>
                  <th style="padding: 2px; text-align: left;">Service</th>
                  <td style="padding: 2px; text-align: left;color: #666;">{{ scan.voucher?.service_name || '-' }}</td>
                </tr>
                <tr>
                  <th style="padding: 2px; text-align: left;">Boutique</th>
                  <td style="padding: 2px; text-align: left;color: #555454;">{{ scan.store?.name || '-' }}</td>
                </tr>
                <tr>
                  <th style="padding: 2px; text-align: left;">Téléphone</th>
                  <td style="padding: 2px; text-align: left;color: #555454;">{{ scan.phone || '-' }}</td>
                </tr>
                <tr>
                  <th style="padding: 2px; text-align: left;">Scanné le</th>
                  <td style="padding: 2px; text-align: left;color: #555454;">{{ scan.updated_at }}</td>
                </tr>
                <tr>
                  <th style="padding: 2px; text-align: left;">Montant</th>
                  <td style="font-weight: bold; font-size: 14px; padding: 2px; text-align: left;">
                    {{ scan.voucher?.amount ? CurrencyFormatting(scan.voucher.amount) + ' DZD' : 'Remboursé' }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <v-btn color="gifty"
                 block
                 text
                 class="v-btn--active mt-5"
                 depressed
                 @click="printPdf(scan)">
            <v-icon left>mdi-cloud-print-outline</v-icon>
            Imprimer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scan: {},
      dialog: false,
    };
  },
  methods: {
    printPdf(item) {
      const clonedItem = JSON.parse(JSON.stringify(item));
      this.scan = {...clonedItem};
      this.dialog = true;

      const options = {
        name: '_blank',
        autoClose: true,
        windowTitle: 'Bon Gifty',
      };

      setTimeout(() => {
        this.$htmlToPaper('print', options);
      }, 500);


    },
  },


};
</script>

<style scoped>
@media print {
  body {
    margin: 0;
    padding: 0;
    font-size: 10px;
    max-width: 58mm;
    width: 100%;
    color: #000;
  }

  #print {
    display: block;
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 10px;
  }

  th, td {
    padding: 2px;
    text-align: left;
  }

  td[style*="font-weight: bold"] {
    font-size: 14px;
  }

  img {
    max-width: 30mm;
    height: auto;
  }

  h3 {
    font-size: 14px;
  }

  p {
    font-size: 10px;
    color: #666;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
</style>
